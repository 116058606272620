.footer {
  min-height: 80px;
  padding-bottom: 8px;
  background-color: #fafafa;
  padding-top: 30px;
  position: relative;
  text-align: center;
  line-height: 200%;
  z-index: 10;

  .footer-copy {
    margin-top: 30px;
  }

  a {
    text-decoration: none;
  }

  .footer-link {
    color: black;
    margin: 0 5px;
  }

  .line {
    @media (max-width: 1000px) {
      display: block;
    }
  }
}
