h2 {
  small {
    color: #777;
    font-size: 16px;
  }
}

main {
  min-height: 80vh;
  padding-top: 76px;
}

.profile-img {
  width: 157px;
  height: 157px;
  border-radius: 78.5px;
  object-fit: cover;
  object-position: 50% 0;
}

.certification-img {
  width: 240px;
  height: 300px;
}

.modal-dialog-wide {
  max-width: 100%;
  width: 50%;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

#new-prescription-modal .modal-body {
  padding: 20px 50px;
}

.w-65 {
  width: 65% !important;
}
