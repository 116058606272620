#side-menu {
  position: fixed;
  left: 130%;
  top: 8%;
  width: 400%;
  height: 100%;
  border: black;
  background-color: rgb(230, 234, 238);
  transition: 0.5s;
}

#side-menu.open {
  left: 75%;
}

ul.mail-ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    border-top: 1px solid #3b4046;
  }
}

#mail-button.btn-toggle {
  transform: translateX(-200px);
  transition: 0.5s;
}
